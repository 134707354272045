import classNames from "classnames"
import Link from "next/link"
import truncateEthAddress from "truncate-eth-address"
import { useEnsName, useDisconnect } from "wagmi"

import { ConnectKitButton } from "connectkit"
import { useAccountAutoconnect } from "../web3"
import useBrowserDetect from "../hooks/useBrowserDetect"

type Props = {
  className?: string
  hideConnect?: boolean
}

export default function Header(props: Props) {
  const { className, hideConnect } = props
  const { address } = useAccountAutoconnect()
  const { data: ensName } = useEnsName()
  const { disconnect } = useDisconnect()
  const { isMobileMetamask } = useBrowserDetect()

  return (
    <div
      className={classNames(
        "max-w-7px m-auto flex justify-end items-right px-[24px] md:px-[38px] py-[28px]",
        className
      )}
    >
      <nav>
        <ol className="flex space-x-[16px]">
          <li className="hidden md:inline">
            <a href="/#schedule" className="px-[16px] py-[10px] text-gray-300">
              Schedule
            </a>
          </li>
          <li className="hidden md:inline">
            <a href="/#faq" className="px-[16px] py-[10px] text-gray-300">
              FAQ
            </a>
          </li>

          {hideConnect ? null : (
            <li>
              {address ? (
                <a
                  onClick={() => !isMobileMetamask && disconnect()}
                  className="cursor-pointer px-[16px] py-[10px] text-gray-100 bg-teal-dark rounded-xl text-sm"
                >
                  {ensName || truncateEthAddress(address)}
                </a>
              ) : (
                <ConnectKitButton.Custom>
                  {({ show }) => (
                    <a
                      onClick={show}
                      className="cursor-pointer px-[16px] py-[10px] text-gray-100 bg-teal-dark rounded-xl text-sm"
                    >
                      Connect Wallet
                    </a>
                  )}
                </ConnectKitButton.Custom>
              )}
            </li>
          )}
        </ol>
      </nav>
    </div>
  )
}
