// hook to return whether browser is MobileSafari:

import { useEffect, useState } from "react"

export default function useBrowserDetect() {
  const [detected, setDetected] = useState({
    isMobileSafari: false,
    isMobileMetamask: false
  })
  useEffect(() => {
    const ua = window.navigator.userAgent || ""
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    const webkit = !!ua.match(/WebKit/i)
    const metamask = !!ua.match(/MetaMaskMobile/i)
    const chrome = ua.match(/CriOS/i)
    const isMobileSafari = iOS && webkit && !chrome && !metamask
    const isMobileMetamask = iOS && webkit && metamask
    setDetected({ isMobileSafari, isMobileMetamask })
  }, [])
  return detected
}
